var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "company-tour",
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Company Info")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "checkInOutTimeValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onCheckInOutTimeSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Check In And Out Time")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "8"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "check_in_out_time",
      "vid": "check_in_out_time"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_vm._l(_vm.checkInOutTime, function (item, index) {
          return _c('div', {
            key: item === null || item === void 0 ? void 0 : item.id,
            staticClass: "d-inline-block mr-1"
          }, [_c('b-form-group', {
            attrs: {
              "label": item === null || item === void 0 ? void 0 : item.label,
              "label-for": "check-".concat(index)
            }
          }, [_c('b-form-timepicker', {
            attrs: {
              "id": "check-".concat(index),
              "locale": "en"
            },
            model: {
              value: item.value,
              callback: function callback($$v) {
                _vm.$set(item, "value", $$v);
              },
              expression: "item.value"
            }
          })], 1)], 1);
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_vm.isCheckInOutTimeSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "workHoursValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onWorkHoursSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Work Hours")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "work_hours",
      "vid": "work_hours"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Work Hours",
            "label-for": "work_hours"
          }
        }, [_c('b-form-timepicker', {
          attrs: {
            "id": "work_hours",
            "locale": "en",
            "hour12": false
          },
          model: {
            value: _vm.workHours,
            callback: function callback($$v) {
              _vm.workHours = $$v;
            },
            expression: "workHours"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "8"
    }
  }, [_vm.isWorkHoursSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "delayBufferMinutesValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onDelayBufferMinutesSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Delay Buffer Minutes")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Delay Buffer Minutes",
      "vid": "delay_buffer_minutes",
      "rules": "required|integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "delay_buffer_minutes",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Delay Buffer Minutes"
          },
          model: {
            value: _vm.delayBufferMinutes,
            callback: function callback($$v) {
              _vm.delayBufferMinutes = $$v;
            },
            expression: "delayBufferMinutes"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "8"
    }
  }, [_vm.isDelayBufferMinutesSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "extremeDelayBufferMinutesValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onExtremeDelayBufferMinutesSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Extreme Delay Buffer "), _c('br'), _vm._v(" Minutes")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Extreme Delay Buffer Minutes",
      "vid": "extreme_delay_buffer_minutes",
      "rules": "required|integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "extreme_delay_buffer_minutes",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Extreme Delay Buffer Minutes"
          },
          model: {
            value: _vm.extremeDelayBufferMinutes,
            callback: function callback($$v) {
              _vm.extremeDelayBufferMinutes = $$v;
            },
            expression: "extremeDelayBufferMinutes"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "8"
    }
  }, [_vm.isExtremeDelayBufferMinutesSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "overTimeMinutesValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onOverTimeMinutesSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Over Time Minutes")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Over Time Minutes",
      "vid": "over_time_minutes",
      "rules": "required|integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "over_time_minutes",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Over Time Minutes"
          },
          model: {
            value: _vm.overTimeMinutes,
            callback: function callback($$v) {
              _vm.overTimeMinutes = $$v;
            },
            expression: "overTimeMinutes"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "8"
    }
  }, [_vm.isOverTimeMinutesSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "minOverTimeMinutesValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.minOnOverTimeMinutesSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Minimum Over Time "), _c('br'), _vm._v(" Minutes")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Minimum Over Time Minutes",
      "vid": "min_over_time_minutes",
      "rules": "required|integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "min_over_time_minutes",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Minimum Over Time Minutes"
          },
          model: {
            value: _vm.minOverTimeMinutes,
            callback: function callback($$v) {
              _vm.minOverTimeMinutes = $$v;
            },
            expression: "minOverTimeMinutes"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "8"
    }
  }, [_vm.isMinOverTimeMinutesSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "logoValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onLogoSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Company Logo")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Business Card (Front)",
      "rules": "size:2048|ext:jpeg,png,jpg",
      "vid": "business_card_front"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-file', {
          attrs: {
            "id": "business_card_front",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Choose a file or drop it here...",
            "drop-placeholder": "Drop file here..."
          },
          model: {
            value: _vm.companyLogoInput,
            callback: function callback($$v) {
              _vm.companyLogoInput = $$v;
            },
            expression: "companyLogoInput"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-img', {
    staticClass: "small-image mb-1",
    attrs: {
      "src": _vm.companyLogo
    }
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "4"
    }
  }, [_vm.companyLogoSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "nameValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onNameSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Company Name")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Company Name",
      "vid": "name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Name"
          },
          model: {
            value: _vm.companyName,
            callback: function callback($$v) {
              _vm.companyName = $$v;
            },
            expression: "companyName"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "8"
    }
  }, [_vm.isNameSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "emailValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onEmailSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Company Email")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Company Email",
      "vid": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "email",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Email"
          },
          model: {
            value: _vm.companyEmail,
            callback: function callback($$v) {
              _vm.companyEmail = $$v;
            },
            expression: "companyEmail"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "8"
    }
  }, [_vm.isEmailSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "mobileValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onMobileSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Company Mobile")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Company Mobile",
      "vid": "mobile"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "mobile",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Mobile"
          },
          model: {
            value: _vm.companyMobile,
            callback: function callback($$v) {
              _vm.companyMobile = $$v;
            },
            expression: "companyMobile"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "8"
    }
  }, [_vm.isMobileSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "addressValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onAddressSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_vm._v("Company Address")])]), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "4"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Company Address",
      "vid": "address"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "address",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Address",
            "rows": "3"
          },
          model: {
            value: _vm.companyAddress,
            callback: function callback($$v) {
              _vm.companyAddress = $$v;
            },
            expression: "companyAddress"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "6"
    }
  }, [_vm.isAddressSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    staticClass: "invoice-tour",
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" Invoice Number Generate: #"), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.invoicePrefix + _vm.invoiceCode) + " ")])], 1)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "invoicePrefixValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onInvoicePrefixSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice Prefix",
      "label-for": "invoice-prefix"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "invoice_prefix",
      "vid": "invoice_prefix",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "invoice-prefix",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "INV"
          },
          model: {
            value: _vm.invoicePrefix,
            callback: function callback($$v) {
              _vm.invoicePrefix = $$v;
            },
            expression: "invoicePrefix"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "10"
    }
  }, [_vm.isInvoicePrefixSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "invoiceCodeValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onInvoiceCodeSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice Code",
      "label-for": "invoice-code"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "invoice_code",
      "vid": "invoice_code",
      "rules": "required|integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "invoice-code",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "1000",
            "disabled": _vm.isDisableInvoiceCode
          },
          model: {
            value: _vm.invoiceCode,
            callback: function callback($$v) {
              _vm.invoiceCode = $$v;
            },
            expression: "invoiceCode"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "10"
    }
  }, [_vm.isInvoiceCodeSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.isDisableInvoiceCode
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    staticClass: "payslip-tour",
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v(" Payslip Number Generate: #"), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.payslipPrefix + _vm.payslipCode) + " ")])], 1)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "payslipPrefixValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onPayslipPrefixSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice Prefix",
      "label-for": "payslip-prefix"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "payslip_prefix",
      "vid": "payslip_prefix",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "payslip-prefix",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "PAY"
          },
          model: {
            value: _vm.payslipPrefix,
            callback: function callback($$v) {
              _vm.payslipPrefix = $$v;
            },
            expression: "payslipPrefix"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "10"
    }
  }, [_vm.isPayslipPrefixSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "payslipCodeValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onPayslipCodeSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Payslip Code",
      "label-for": "payslip-code"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "payslip_code",
      "vid": "payslip_code",
      "rules": "required|integer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "payslip-code",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "1000",
            "disabled": _vm.isDisablePayslipCode
          },
          model: {
            value: _vm.payslipCode,
            callback: function callback($$v) {
              _vm.payslipCode = $$v;
            },
            expression: "payslipCode"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "10"
    }
  }, [_vm.isPayslipCodeSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.isDisablePayslipCode
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    staticClass: "default-account-tour",
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Default Account:")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "defaultAccountValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onDefaultAccountSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Account",
      "label-for": "account-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "account",
      "vid": "account_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('v-select', {
          attrs: {
            "id": "account-id",
            "options": _vm.accountsOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose a account"
          },
          model: {
            value: _vm.selectAccountId,
            callback: function callback($$v) {
              _vm.selectAccountId = $$v;
            },
            expression: "selectAccountId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "10"
    }
  }, [_vm.isDefaultAccountSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    staticClass: "default-currency-tour",
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Default Currency:")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "defaultAccountValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onDefaultCurrencySubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Currency",
      "label-for": "account-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "currency",
      "vid": "currency_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('v-select', {
          attrs: {
            "id": "currency-id",
            "options": _vm.currenciesOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "placeholder": "Choose a currency"
          },
          model: {
            value: _vm.selectCurrencyId,
            callback: function callback($$v) {
              _vm.selectCurrencyId = $$v;
            },
            expression: "selectCurrencyId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "10"
    }
  }, [_vm.isDefaultCurrencySubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    staticClass: "default-fiscal-year-tour",
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Default Fiscal year:")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "defaultFiscalYearValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onDefaultFiscalYearSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Start Month",
      "label-for": "month"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "fiscalStartMonth",
      "vid": "fiscalStartMonth",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('v-select', {
          attrs: {
            "id": "fiscalStartMonth",
            "options": _vm.monthNameConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose a month"
          },
          model: {
            value: _vm.selectFiscalStartMonth,
            callback: function callback($$v) {
              _vm.selectFiscalStartMonth = $$v;
            },
            expression: "selectFiscalStartMonth"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Next Month",
      "label-for": "month"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "fiscalEndMonth",
      "vid": "fiscalEndMonth",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('v-select', {
          attrs: {
            "id": "fiscalEndMonth",
            "options": _vm.monthNameConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose a month"
          },
          model: {
            value: _vm.selectFiscalEndMonth,
            callback: function callback($$v) {
              _vm.selectFiscalEndMonth = $$v;
            },
            expression: "selectFiscalEndMonth"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "8"
    }
  }, [_vm.isFiscalYearSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    staticClass: "default-date-tour",
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Default Date Format:")])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('validation-observer', {
    ref: "defaultDateFormatValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onDefaultDateFormatSubmit($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Select a date format",
      "label-for": "dateFormat"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "dateFormat",
      "vid": "dateFormat",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var errors = _ref20.errors;
        return [_c('v-select', {
          attrs: {
            "id": "dateFormat",
            "options": _vm.dateFormatConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose a format"
          },
          model: {
            value: _vm.selectDateFormat,
            callback: function callback($$v) {
              _vm.selectDateFormat = $$v;
            },
            expression: "selectDateFormat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "align-self-center",
    attrs: {
      "md": "9"
    }
  }, [_vm.isSelectDateFormatSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }