export const dateFormatConstants = [
  { name: "YYYY-MM-DD (2024-10-08)", value: "YYYY-MM-DD" },
  { name: "MM-DD-YYYY (10-08-2024)", value: "MM-DD-YYYY" },
  { name: "DD/MM/YYYY (08/10/2024)", value: "DD/MM/YYYY" },
  { name: "DD-MM-YYYY (08-10-2024)", value: "DD-MM-YYYY" },
  { name: "MMM DD, YYYY (Oct 08, 2024)", value: "MMM DD, YYYY" },
  { name: "MMMM DD, YYYY (October 08, 2024)", value: "MMMM DD, YYYY" },
  { name: "DD MMMM YYYY (08 October 2024)", value: "DD MMMM YYYY" },
  { name: "DD MMM YYYY (08 Oct 2024)", value: "DD MMM YYYY" },
  { name: "YYYY/MM/DD (2024/10/08)", value: "YYYY/MM/DD" },
  { name: "MM/DD/YYYY (10/08/2024)", value: "MM/DD/YYYY" }
];
